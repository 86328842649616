const baseURL2022 = 'https://d23hwblhugcea5.cloudfront.net/';
const scenesSelector = `${baseURL2022}Global/Gui/TEXTURES/SelectSceneMenu/`;

const demosOrigin = [
  {
    url: 'https://cola.ozonemetaverse.io ',
    name: 'Soda Cola',
    category: 'ads',
    description:
      'A Proof of Concept for a new type of interactive advertisement experience. Get your audience and customers engaged in metaverse ads they won’t forget with gamification elements. In this first experience, you become a polar bear collecting Soda bottles before taking a trip to the moon with Santa after you successfully complete your quest. ',
    enabled: true,
    image: `${scenesSelector}Scenes/sodaCola.png`,
  },
  {
    url: 'https://chainxr.art/',
    name: 'Chain Art Foundation',
    category: 'art',
    description:
      'A virtual museum featuring artwork and sculptures in a fantastic metaverse island landscape, curated by the Chain Art Foundation.',
    enabled: true,
    image: `${scenesSelector}moreWorlds/Chain.png`,
  },
  {
    url: 'https://vr.superbloom.miami/',
    name: 'SuperBloom',
    description:
      "This interactive 3D Metaverse is the digital twin of SuperBloom exhibit at Art basel in Wynwood Miami District. The Art is a first of its kind physical-digital public art piece by artist Rob Buchholz. The actual physical piece is 35 feet tall, weighs 14 tons, has 20,000 LED pixels, and 35,000 hand-crafted mosaic tiles. Existing in both the real world and in the metaverse, it's a truly hybrid art installation in a Miami metaverse digital twin city block.",
    category: 'art',
    enabled: true,
    image: `${scenesSelector}moreWorlds/superBloom.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/artGallery/',
    name: 'Art Gallery',
    description:
      'This virtual Art Gallery can house the newest art collections within its modern structure and design. There is also a spacious outdoor area that can be used in addition to the indoor space.',
    category: 'art',
    enabled: true,
    image: `${scenesSelector}moreWorlds/artGallery.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/expoCenter/',
    name: 'Expo Center',
    description:
      'Showcase the newest in technological and human advancements in the Expo Center! This virtual environment features a spacious outdoor plaza and 10 indoor spaces for all your exhibitions or conference needs in the metaverse.',
    category: 'conf_fest',
    enabled: true,
    image: `${scenesSelector}moreWorlds/expoCenter.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/museumExpo',
    name: 'Museum Expo',
    description:
      'A virtual exposition space and event space environment. This virtual Museum Expo has plenty of space and wall panels for all manner of presentations and exhibitions, including a center courtyard. The outdoor space outside the structure includes an amphitheater. So you can have multiple talks and presentations going at the same time.',
    category: 'conf_fest',
    enabled: true,
    image: `${scenesSelector}moreWorlds/museumExpo.png`,
  },
  {
    url: 'https://stage.ozonemetaverse.io',
    name: 'Stage',
    description:
      'This virtual environment is built for entertainment whether it’s for a virtual concert, rave, or any other stage performance!',
    category: 'conf_fest',
    enabled: true,
    image: `${scenesSelector}Scenes/musicStage.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/classRoom',
    name: 'Classroom',
    category: 'education',
    description:
      'A spacious classroom environment for virtual meetings, virtual classes, and other virtual sessions. A perfect setting for educational purposes.',
    enabled: true,
    image: `${scenesSelector}moreWorlds/classRoom.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/outdoorAmphitheater',
    name: 'Outdoor Amphitheater',
    category: 'education',
    description:
      'An outdoor venue that features a gathering area connected to an amphitheater with a stage. This virtual amphitheater is perfect for your special virtual events that can host a big crowd.',
    enabled: true,
    image: `${scenesSelector}moreWorlds/outdoorAmphitheater.png`,
  },
  {
    url: 'https://burnerzone.ozonemetaverse.io',
    name: 'Burner Zone',
    category: 'events_solutions',
    enabled: false,
    image: `${scenesSelector}Scenes/burnerZone.png`,
  },
  {
    url: 'https://conference.ozonemetaverse.io',
    name: 'Conference Space',
    category: 'events_solutions',
    enabled: true,
    image: `${scenesSelector}Scenes/conferenceRoom.png`,
  },
  {
    url: 'https://stage.ozonemetaverse.io',
    name: 'Music Stage',
    category: 'events_solutions',
    enabled: true,
    image: `${scenesSelector}Scenes/musicStage.png`,
  },
  {
    url: 'https://alphabet.ozonemetaverse.io',
    name: 'Learn the Alphabet',
    description:
      'A simple and basic game set in a virtual park for children 2-3+ years old to learn the alphabet.',
    category: 'kidsLearning',
    enabled: true,
    image: `${scenesSelector}Scenes/kidsPark.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/cityPark',
    name: 'City Park',
    description:
      'This fun and lively park is a great setting for virtual learning and play for kids! Featuring a skate park, playgrounds, benches, and more!',
    category: 'kidsLearning',
    enabled: true,
    image: `${scenesSelector}moreWorlds/cityPark.png`,
  },
  {
    url: 'https://dinos.ozonemetaverse.io',
    name: 'Meet Dinosaurs!',
    description:
      'A virtual interactive animal Park where visitors can learn about and interact with dinosaurs and extinct species.',
    category: 'kidsLearning',
    enabled: true,
    image: `${scenesSelector}Scenes/meetDinosaurs.png`,
  },
  {
    url: 'https://shop.ozonemetaverse.io',
    name: 'Shopping Square',
    description:
      'A virtual shopping store with a spacious cylindrical design and product pedestals. Got products to sell and showcase? We’ve got the perfect virtual shop for you and your customers!',
    category: 'mall_store',
    enabled: true,
    image: `${scenesSelector}moreWorlds/2035.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/urbanMarketplace',
    name: 'City Center',
    description:
      'A virtual environment set in the center of a city with digital billboards placed on the facades of buildings. A virtual outdoor setting that is reminiscent of modern-day metropolitan cities and their buzzing and exciting energy.',
    category: 'mall_store',
    enabled: true,
    image: `${scenesSelector}moreWorlds/urbanMarketplace.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/metaMall',
    name: 'Meta Mall',
    description:
      'This virtual mall environment features multi-level floors with multiple store spaces to set up your various virtual shops. A great virtual indoor setting for your virtual shopping mall experience!',
    category: 'mall_store',
    enabled: true,
    image: `${scenesSelector}moreWorlds/metaMall.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/boardRoom',
    name: 'Board Room',
    description:
      'A virtual environment that combines an outdoor and indoor setting. The interior Boardroom features 2 back-to-back screens, long desk, and chairs for your virtual business meetings and  presentations. The outdoor courtyard features a more casual and social setting with billboards/screens and outdoor seating. The Boardroom is perfect for your virtual business and social purposes.',
    category: 'meeting_rooms',
    enabled: true,
    image: `${scenesSelector}moreWorlds/boardRoom.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/conferenceRoom',
    name: 'Conference Room',
    description:
      'The Conference Room features a spacious area with a large screen for your virtual presentations and wall panels for your conference materials. It also features 30 seats and standing room area for your guests. This virtual space is surrounded by an outdoor area for guests to wander around and socialize outside of the Conference Room.',
    category: 'meeting_rooms',
    enabled: true,
    image: `${scenesSelector}moreWorlds/conferenceRoom.png`,
  },
  {
    url: 'https://knight.ozonemetaverse.io',
    name: 'Princess Knight',
    description:
      'A medieval action-adventure video game where the heroic knight rescues the princess from the evil orcs that have invaded her kingdom.',
    category: 'instant_games',
    enabled: false,
    image: `${scenesSelector}Scenes/princessKnight.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/metaCities1',
    name: 'Meta City I',
    description:
      'A large virtual meta city with tall skyscrapers and business establishments surrounded by urban neighborhoods.',
    category: 'meta_cities',
    enabled: true,
    image: `${scenesSelector}moreWorlds/metaCities1.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/metaCities2',
    name: 'Meta City II',
    description:
      'A virtual urban neighborhood set in city-style blocks with various buildings that reflects living in the big city.',
    category: 'meta_cities',
    enabled: true,
    image: `${scenesSelector}moreWorlds/metaCities2.png`,
  },
  {
    url: 'https://app.ozonemetaverse.io/metaCities3',
    name: 'Meta City III',
    description:
      'A colorful and charming virtual neighborhood with small parks, benches, cafes, and small neighborhood restaurants to recreate that small town feel.',
    category: 'meta_cities',
    enabled: true,
    image: `${scenesSelector}moreWorlds/metaCities3.png`,
  },
];

export let demos = [...demosOrigin];

export function filterDemos(category: string) {
  let firstMatchEl = demos.find((el) => el.category === category);

  if (firstMatchEl) {
    const start = demosOrigin.indexOf(firstMatchEl);
    let tail = demosOrigin.slice(start);
    let head = demosOrigin.slice(0, start);

    demos = [...tail, ...head];
  }
}
