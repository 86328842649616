import React from 'react';
import Image from 'next/image';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import bgs from './backgrounds.module.css';
import typography from '../../ui-kit/typography/typography.module.css';
import styles from './discord.module.css';

const Discord = () => {
  const open = (url) => {
    if (window) {
      window.open(url);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.content}>
          <h3 className={typography.pageTitle}>Join our Discord group</h3>
          <div className={`${styles.imageWrapper} ${styles.mobile}`}>
            <Image
              className={styles.image}
              src='/phone.png'
              alt='image'
              layout='fill'
            />
          </div>
          <p className={typography.description}>
            Be in sync with the Ozone project announcements and product updates.
            Provide feedback and ask questions. Together we will build new
            worlds.
          </p>
          <div className={styles.button}>
            <DefaultButton
              type='primary'
              style={{ width: 186 }}
              onClick={() => open('https://discord.gg/KKkG42Bg')}
            >
              <span style={{ color: '#fff' }}>Join</span>
            </DefaultButton>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            src='/phone.png'
            alt='image'
            layout='fill'
          />
        </div>
      </div>
      <div className={bgs.whiteBg} />
    </div>
  );
};

export default Discord;
