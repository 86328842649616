import React, { useState } from 'react';
import Image from 'next/image';
import { Input, Button, Form, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { mail } from './icons';
import { useWindowSize } from '@src/components/hooks/useWindowDimensions';
import bgs from './backgrounds.module.css';
import typography from '../../ui-kit/typography/typography.module.css';
import styles from './mail.module.css';

const Mail = () => {
  const [isRegistered, setIsRegistred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const size = useWindowSize();

  const onSubmit = (values) => {
    setIsLoading(true);
    setIsError(false);

    fetch &&
      fetch(`/api/mail/subscribe/?email=${values.email}`, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          setIsLoading(false);

          if (!result.status) {
            setIsError(true);
            return;
          }
          setIsRegistred(true);
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
        });
  };

  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <Image
          alt='image'
          src={
            size.width && size.width >= 1000
              ? '/bg_mail.png'
              : '/bg_mail_mobile.png'
          }
          layout='fill'
        />
        <div className={styles.icon}>{mail}</div>
        <div className={styles.content}>
          {isRegistered ? (
            <h3 className={`${typography.pageTitle} ${styles.title}`}>
              We successfully registered your email!
            </h3>
          ) : (
            <>
              <h3 className={`${typography.pageTitle} ${styles.title}`}>
                Join our mailing lists for updates and news
              </h3>
              <Form
                layout='horizontal'
                className={styles.form}
                onFinish={onSubmit}
              >
                <Form.Item
                  name='email'
                  rules={[
                    {
                      type: 'email',
                      message: (
                        <span className={styles.errorMessage}>
                          The input is not valid E-mail
                        </span>
                      ),
                    },
                    {
                      required: true,
                      message: (
                        <span className={styles.errorMessage}>
                          Please input your email
                        </span>
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder='Enter your email address'
                    className={styles.input}
                  />
                </Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={styles.button}
                >
                  {isLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: '#fff' }}
                          spin
                        />
                      }
                    />
                  ) : (
                    'Subscribe'
                  )}
                </Button>
              </Form>
              {isError && (
                <p className={`${styles.errorMessage} ${styles.error}`}>
                  Something went wrong. Please try again.
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <div className={bgs.grayBg} />
    </div>
  );
};

export default Mail;
