import React from 'react';
import Icon from '@ant-design/icons';
import { logos } from './partners-logos';
import styles from './partners-section.module.css';

export const PartnersSection = () => {
  return (
    <>
      <div className={styles.root}>
        <div className={`${styles.logosGridStrip} ${styles.rotateContainer}`}>
          {logos.map((logo, index) => (
            <Icon
              key={index}
              style={{ marginRight: 10, animationDelay: `${index * 0.1}s` }}
              component={() => (
                <img
                  src={logo.src}
                  alt='icon'
                  width={logo.width}
                  height={logo.height}
                />
              )}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default PartnersSection;
