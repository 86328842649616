import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  forwardRef,
} from 'react';
import { demos } from './demos-scenes';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Carousel, Button } from 'antd';
import { useWindowSize } from '@src/components/hooks/useWindowDimensions';
import DynamicImage from '@src/components/ui-kit/image/DynamicImage';
import styles from './demos-carousel.module.css';

// eslint-disable-next-line consistent-return
function sliceOnSections(arr: Array<object>, count: number) {
  let temp = [] as Array<Array<object>>;
  let start = 0;
  let step = 0;
  const array = arr.filter((el: any) => el.enabled);
  let max = Math.ceil(array.length / count);

  for (let i = 0; i <= max - 1; i++) {
    step += count;
    temp.push(array.slice(start, step));
    start += count;
  }

  return temp;
}

const DemosCarousel = ({ setSelected, selected }, ref) => {
  const carousel = useRef<any>(null);
  const [currentSlideIndex, setCurrenSlideIndex] = useState(0);
  const [countCards, setCountCards] = useState(4);
  const size = useWindowSize();

  const afterChange = useCallback(
    (current) => setCurrenSlideIndex(current),
    [currentSlideIndex]
  );

  const left = () => {
    if (carousel.current) {
      carousel.current.prev();
    }
  };

  const right = () => {
    if (carousel.current) {
      carousel.current.next();
    }
  };

  useEffect(() => {
    if (!size.width) {
      return;
    }

    if (size.width <= 600) {
      setCountCards(1);
      return;
    }

    if (size.width <= 1200) {
      setCountCards(2);
    } else {
      setCountCards(4);
    }
  }, [size?.width]);

  return (
    <div className={styles.root}>
      <div className={styles.controlsLeft}>
        <Button
          onClick={left}
          icon={<LeftCircleFilled className={styles.icon} />}
          className={styles.button}
        ></Button>
      </div>
      <Carousel
        dots={false}
        afterChange={afterChange}
        ref={(node) => {
          carousel.current = node;
          ref.current = node;
        }}
        className={styles.carousel}
      >
        {sliceOnSections(demos, countCards).map((el: any, i) => (
          <div key={i} className={styles.carouselContent}>
            {el.map((el, i) => {
              return (
                <div
                  key={i}
                  className={`${styles.card} ${
                    selected.name === el.name && styles.selected
                  }`}
                  onClick={() => setSelected(el)}
                >
                  <div className={styles.image}>
                    <DynamicImage src={el.image} />
                  </div>
                  <p className={styles.name}>{el.name}</p>
                </div>
              );
            })}
          </div>
        ))}
      </Carousel>
      <div className={styles.controlsRight}>
        <Button
          onClick={right}
          icon={<RightCircleFilled className={styles.icon} />}
          className={styles.button}
        ></Button>
      </div>
    </div>
  );
};

export default forwardRef(DemosCarousel);
