import React from 'react';
import Demos from './demos/Demos';
import bgs from './backgrounds.module.css';
import styles from './demos-section.module.css';

const DemosSection = () => {
  return (
    <div className={styles.root}>
      <div className={styles.demosWrapper}>
        <Demos />
      </div>
      <div className={bgs.grayBg} />
    </div>
  );
};

export default DemosSection;
