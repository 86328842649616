import React from 'react';
import TopSection from './sections/TopSection';
import StudioFeatures from './sections/StudioFeatures';
import Purposes from './sections/Purposes';
import BrowserBased from './sections/BrowserBased';
import Steps from './sections/Steps';
import DemosSection from './sections/DemosSection';
import Discord from './sections/Discrod';
import Mail from './sections/Mail';
import styles from './landing.module.css';

const Landing = () => {
  return (
    <div className={styles.root}>
      <TopSection />
      <StudioFeatures />
      <Purposes />
      <BrowserBased />
      <Steps />
      <DemosSection />
      <Discord />
      <Mail />
    </div>
  );
};

export default Landing;
