import { S3_IMAGES_URL } from 'util/constants';

const CompanyTheme = {
  WB: `${S3_IMAGES_URL}/assets/logos/Warner_Bros_logo.png`,
};

const usersMap = new Map();
usersMap.set('45a8b0f8-ea71-407d-8483-12333ce703f0', {
  userName: 'staging cto',
  logoUrl: CompanyTheme.WB,
});
usersMap.set('4ec49dcb-088b-4ebe-b51c-b91fdbacd043', {
  userName: 'staging dinext',
  logoUrl: CompanyTheme.WB,
});
usersMap.set('e9167ad7-6ab8-4183-a02d-4b46eaa6a22a', {
  userName: 'prod cto',
  logoUrl: CompanyTheme.WB,
});
usersMap.set('b135d229-f8f1-4507-b200-7f4c0f5131a2', {
  userName: 'prod dinext',
  logoUrl: CompanyTheme.WB,
});

export function themeHelper(userId: string) {
  if (usersMap.has(userId)) {
    return usersMap.get(userId).logoUrl as string;
  }

  return undefined;
}
