import React, { useContext } from 'react';
import { Layout } from 'antd';
import { BrandingContext } from 'pages/_app';
import { unstable_getServerSession } from 'next-auth/next';
import { authOptions } from './api/auth/[...nextauth]';
import Landing from '@src/components/landing/Landing';
import { themeHelper } from '@src/theme/themeHelper';
import { prodLinks, S3_IMAGES_URL } from 'util/constants';
import { CustomFooter } from '@ozoneuniverse/ui-components/build-pkg/src/components/footer/CustomFooter';
import { NavigationBar } from '../src/components/navigation-bar/NavigationBar';

const { Header, Content, Footer } = Layout;

export async function getServerSideProps(context) {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  );

  if (!session) {
    return {
      props: {
        isSignIn: false,
        email: '',
        userId: '',
      },
    };
  }

  return {
    props: {
      isSignIn: true,
      email: session.user.email,
      userId: session.user.id,
    },
  };
}

export const Main = ({ isSignIn, email, userId }) => {
  const branding = useContext(BrandingContext);
  return (
    <Layout className='layout'>
      <Header className='header'>
        <NavigationBar
          isSignIn={isSignIn}
          isCreator={false}
          userEmail={email}
          externalLogoURL={themeHelper(userId)}
        ></NavigationBar>
      </Header>
      <Content className='content'>
        <div className='content-root-wrapper' style={{ paddingBottom: 0 }}>
          <Landing />
        </div>
      </Content>
      <Footer className='footer'>
        <CustomFooter
          s3ImagesUrl={S3_IMAGES_URL as string}
          prodLinks={prodLinks}
          externalLogoURL={themeHelper(userId)}
          type={branding === 'ZEPETO' ? 'zepeto' : 'studio'}
        />
      </Footer>
    </Layout>
  );
};

export default Main;
