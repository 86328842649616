import React, { useEffect, useRef } from 'react';
import { categories } from './categories';
import styles from './category-slider.module.css';

const CategorySlider = ({ category, setCategory }) => {
  const card = useRef(null as any);

  useEffect(() => {
    const el = document.getElementById(category);
    const cat = categories.find((e) => e.category === category);
    if (el && cat) {
      const pos = categories.indexOf(cat);
      if (pos >= 3) {
        card.current.scroll((176 * pos + 1) / 2, 0);
      } else {
        card.current.scroll(0, 0);
      }
    }
  }, [category]);

  return (
    <div className={styles.root} ref={card}>
      {categories
        .filter((el) => el.enabled)
        .map((el, i) => {
          return (
            <div
              key={el.category}
              id={el.category}
              onClick={() => setCategory(el.category, true)}
              className={`${styles.card} ${
                category === el.category && styles.cardSelected
              }`}
            >
              {el.name}
            </div>
          );
        })}
    </div>
  );
};

export default CategorySlider;
