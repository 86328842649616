import React from 'react';
import Image from 'next/image';
import bgs from './backgrounds.module.css';
import typography from '../../ui-kit/typography/typography.module.css';
import styles from './purposes.module.css';

const Purposes = () => {
  return (
    <div className={styles.root}>
      <h3 className={`${typography.pageTitle} ${styles.title}`}>
        Use for different purposes
      </h3>
      <div className={styles.content}>
        <div className={styles.imageGroup}>
          <div className={styles.image}>
            <Image alt='image' src='/purposes_image.png' layout='fill' />
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.card}>
            <p className={typography.title}>
              <span className={styles.bullet}>•</span>Business
            </p>
            <span className={typography.description}>
              Arrange meetings, create shopping experiences for physical and
              digital goods, showcase your art in metaverse galleries. We
              provide the ability to customize the environment to suit your
              preferences. Shop in a major metropolis? Easily!
            </span>
          </div>
          <div className={styles.card}>
            <p className={typography.title}>
              <span className={styles.bullet}>•</span>Social
            </p>
            <span className={typography.description}>
              Are you looking for a way to provide new dimension of experiences
              to your fans and followers? With the Studio and the help of our
              team you can arrange the metaverse performances, concerts, parties
              and immersive quests to your audience.
            </span>
          </div>
          <div className={styles.card}>
            <p className={typography.title}>
              <span className={styles.bullet}>•</span>For personal use
            </p>
            <span className={typography.description}>
              You can create your own world and customize it with our tools.
              Gather friends, chat, walk. With our technology, the distance
              between you and your loved ones is no longer a barrier.
            </span>
          </div>
        </div>
      </div>
      <div className={bgs.whiteBg} />
    </div>
  );
};

export default Purposes;
