import React from 'react';
import Image from 'next/image';
import bgs from './backgrounds.module.css';
import typography from '../../ui-kit/typography/typography.module.css';
import styles from './browser-based.module.css';

const BrowserBased = () => {
  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <div className={styles.imageContainer}>
          <Image
            src='/browser-based-image.png'
            alt='Metaverse world in the browser'
            className={styles.imageBg}
            layout='fill'
          />
        </div>
        <div className={styles.cardBody}>
          <p className={typography.title}>
            Simple to use - Powerful - 100% browser based.
          </p>
          <span className={typography.description}>
            Our platform is built for the web, and accessible anywhere. As
            simple as the website can be. Just click a link and you are in the
            metaverse.
          </span>
        </div>
      </div>
      <div className={bgs.grayBg} />
    </div>
  );
};

export default BrowserBased;
