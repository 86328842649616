import React, { useState } from 'react';
import Image from 'next/image';
import { CSSProperties } from 'react';
import { imagePlaceholder } from 'util/constants';

type Props = {
  src: string;
  style?: CSSProperties;
};

const DynamicImage: React.FC<Props> = ({ src, style }) => {
  const [error, setError] = useState(false);
  return (
    <Image
      style={{ objectFit: 'cover', ...style }}
      layout='fill'
      placeholder='blur'
      blurDataURL={imagePlaceholder}
      onError={() => setError(true)}
      src={src}
      alt='image'
    />
  );
};

export default DynamicImage;
