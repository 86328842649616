export const categories = [
  {
    name: 'Ads',
    category: 'ads',
    enabled: true,
  },
  {
    name: 'Art Exhibits',
    category: 'art',
    enabled: true,
  },
  {
    name: 'Conferences & Festivals',
    category: 'conf_fest',
    enabled: true,
  },
  {
    name: 'Education',
    category: 'education',
    enabled: true,
  },
  {
    name: 'Events Solutions',
    category: 'events_solutions',
    enabled: true,
  },
  {
    name: 'Instant Games',
    category: 'instant_games',
    enabled: false,
  },
  {
    name: 'Kids Learning',
    category: 'kidsLearning',
    enabled: true,
  },
  {
    name: 'Malls & Stores',
    category: 'mall_store',
    enabled: true,
  },
  {
    name: 'Meeting Rooms',
    category: 'meeting_rooms',
    enabled: true,
  },
  {
    name: 'Meta Cities',
    category: 'meta_cities',
    enabled: true,
  },
];
