import { S3_IMAGES_URL } from 'util/constants';

export const logos = [
  {
    src: `${S3_IMAGES_URL}/assets/logos/shopify.svg`,
    width: 80,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/ChatGPT.svg`,
    width: 96,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Flow.svg`,
    width: 70,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Blocto.svg`,
    width: 77,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/bitget.svg`,
    width: 82,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Metamask-logo.svg`,
    width: 140,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Avalanche.svg`,
    width: 138,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/arbitrum.svg`,
    width: 110,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/cosmos.svg`,
    width: 149,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/polygon.svg`,
    width: 121,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Dapper.svg`,
    width: 90,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/shopify.svg`,
    width: 80,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/ChatGPT.svg`,
    width: 96,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Flow.svg`,
    width: 70,
    height: 28,
  },
  {
    src: `${S3_IMAGES_URL}/assets/logos/Blocto.svg`,
    width: 77,
    height: 28,
  },
];
