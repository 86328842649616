import React from 'react';
import { features } from './icons';
import typography from '../../ui-kit/typography/typography.module.css';
import bgs from './backgrounds.module.css';
import styles from './studio-features.module.css';

const StudioFeatures = () => {
  return (
    <div className={styles.root} id='studio_features'>
      <h3 className={`${typography.pageTitle} ${styles.title}`}>
        Studio features
      </h3>
      <div className={styles.grid}>
        <div className={styles.card}>
          <div className={styles.icon}>{features.featOne}</div>
          <div className={styles.cardText}>
            <p className={typography.title}>Use Web3 assets in your world</p>
            <span className={typography.description}>
              Find and purchase your Web3 Assets and seamlessly integrate into
              your world with help of the Studio
            </span>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.icon}>{features.featTwo}</div>
          <div className={styles.cardText}>
            <p className={typography.title}>3D No-Code Studio</p>
            <span className={typography.description}>
              No special skills required. Just bring your imagination, create
              and share with a few button clicks.
            </span>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.icon}>{features.featThree}</div>
          <div className={styles.cardText}>
            <p className={typography.title}>Free access</p>
            <span className={typography.description}>
              Just register on the platform and start creating.
            </span>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.icon}>{features.featFour}</div>
          <div className={styles.cardText}>
            <p className={typography.title}>Own your metaverse</p>
            <span className={typography.description}>
              Whatever you create in Studio is yours. Just publish and share
              your world.
            </span>
          </div>
        </div>
      </div>
      <div className={bgs.grayBg} />
    </div>
  );
};

export default StudioFeatures;
