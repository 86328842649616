import React from 'react';
import { Divider } from 'antd';
import { SIGN_IN_URL } from 'util/constants';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import bgs from './backgrounds.module.css';
import typography from '../../ui-kit/typography/typography.module.css';
import styles from './steps.module.css';

const Steps = () => {
  const router = useRouter();
  const { data: session } = useSession();

  const create = () => {
    if (session) {
      router.push('/user/studio');
    } else {
      if (window) {
        window.location.assign(SIGN_IN_URL);
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h3 className={`${typography.pageTitle} ${styles.title}`}>
          4 steps to create your world
        </h3>
        <DefaultButton onClick={create} type='primary'>
          <span style={{ color: '#fff' }}>Create your world</span>
        </DefaultButton>
      </div>
      <div className={styles.steps}>
        <div className={styles.stepCard}>
          <div className={styles.stepWrapper}>
            <div className={styles.step}>1</div>
            <Divider className={styles.line} dashed />
          </div>
          <div className={styles.info}>
            <p className={typography.title}>Register</p>
            <span className={typography.description}>Sign up or sign in</span>
          </div>
        </div>
        <div className={styles.stepCard}>
          <div className={styles.stepWrapper}>
            <div className={styles.step}>2</div>
            <Divider className={styles.line} dashed />
          </div>
          <div className={styles.info}>
            <p className={typography.title}>Create a world</p>
            <span className={typography.description}>
              Click the “create scene” button
            </span>
          </div>
        </div>
        <div className={styles.stepCard}>
          <div className={styles.stepWrapper}>
            <div className={styles.step}>3</div>
            <Divider className={styles.line} dashed />
          </div>
          <div className={styles.info}>
            <p className={typography.title}>Customize</p>
            <span className={typography.description}>
              Use OZONE’s 3D library bring your own web3 and digital assets to
              create extraordinary experience.
            </span>
          </div>
        </div>
        <div className={styles.stepCard}>
          <div className={styles.stepWrapper}>
            <div className={styles.step}>4</div>
          </div>
          <div className={styles.info}>
            <p className={typography.title}>Save & Share</p>
            <span className={typography.description}>
              Click save button to preserve your changes. Click “Share” button
              and send the link for your newly created metaverse to anyone you
              want.
            </span>
          </div>
        </div>
      </div>
      <div className={bgs.whiteBg} />
    </div>
  );
};

export default Steps;
