import React, { useState, useRef } from 'react';
import CategorySlider from './CategorySlider';
import DynamicImage from '@src/components/ui-kit/image/DynamicImage';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import DemosCarousel from './DemosCarousel';
import { openNewTab } from 'util/helpers';
import { demos, filterDemos } from './demos-scenes';
import styles from './demos.module.css';

const Demos = () => {
  const [selected, setSelected] = useState(demos[0]);
  const carousel = useRef<any>(null);

  const setCategory = (category: string, isManual?: boolean) => {
    if (isManual) {
      filterDemos(category);
      carousel?.current?.goTo(0);
    }
    const item = demos.find((el) => {
      if (el.category === category && el.enabled) {
        return el;
      }
      return undefined;
    });

    item && setSelected(item);
  };

  return (
    <div className={styles.root}>
      <CategorySlider category={selected.category} setCategory={setCategory} />
      <div className={styles.selectedDemo}>
        <div className={styles.image}>
          <DynamicImage src={selected.image} />
        </div>
        <div className={styles.info}>
          <p className={styles.title}>{selected.name}</p>
          {selected?.description && (
            <p className={styles.desc}>{selected.description}</p>
          )}
          <DefaultButton
            style={{ width: 180 }}
            type='primary'
            onClick={() => openNewTab(selected.url)}
          >
            <span style={{ color: '#fff' }}>Run demo</span>
          </DefaultButton>
        </div>
        <div className={styles.bg} />
      </div>
      <DemosCarousel
        setSelected={setSelected}
        selected={selected}
        ref={carousel}
      />
    </div>
  );
};

export default Demos;
